import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [{
    path: '/',
    component: () => import(/* webpackChunkName: "main" */ '../views/App.vue'),
    children: [{
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    }, {
        path: 'competitions',
        component: () => import(/* webpackChunkName: "competitions" */ '../views/Competitions.vue')
    }, {
        path: 'competitions/:id',
        component: () => import(/* webpackChunkName: "competition" */ '../views/Competition.vue')
    }, {
        path: 'competitors',
        component: () => import(/* webpackChunkName: "competitors" */ '../views/Competitors.vue')
    }, {
        path: 'competitors/:id',
        component: () => import(/* webpackChunkName: "competitor" */ '../views/Competitor.vue')
    }, {
        path: 'rankings',
        component: () => import(/* webpackChunkName: "rankings" */ '../views/Rankings.vue')
    }, {
        path: 'series/:id',
        component: () => import(/* webpackChunkName: "series-single" */ '../views/SeriesSingle.vue')
    }, {
        path: 'records',
        component: () => import(/* webpackChunkName: "records" */ '../views/Records.vue')
    }]
}, {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin-app" */ '../views/AdminApp.vue'),
    children: [{
        path: '',
        component: () => import(/* webpackChunkName: "admin-home" */ '../views/AdminHome.vue')
    }, {
        path: 'obs',
        component: () => import(/* webpackChunkName: "admin-obs" */ '../views/AdminOBS.vue')
    }, {
        path: 'rankings/series',
        component: () => import(/* webpackChunkName: "admin-rankings-series" */ '../views/AdminRankingsSeries.vue')
    }, {
        path: 'rankings/series/edit/:id',
        component: () => import(/* webpackChunkName: "admin-rankings-series-edit" */ '../views/AdminRankingsSeriesEdit.vue')
    }, {
        path: 'points',
        component: () => import(/* webpackChunkName: "admin-points" */ '../views/AdminPoints.vue')
    }, {
        path: 'stats/competitor',
        component: () => import(/* webpackChunkName: "admin-stats-competitor" */ '../views/AdminStatsCompetitor.vue')
    }, {
        path: 'stats/competition',
        component: () => import(/* webpackChunkName: "admin-stats-competition" */ '../views/AdminStatsCompetition.vue')
    }, {
        path: 'settings',
        component: () => import(/* webpackChunkName: "admin-settings" */ '../views/AdminSettings.vue')
    }]
}, {
    path: '/admin/login',
    component: () => import(/* webpackChunkName: "admin-login" */ '../views/AdminLogin.vue')
}, {
    path: '/admin/obs/round',
    component: () => import(/* webpackChunkName: "admin-obs-round" */ '../views/AdminOBSRound.vue')
}];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/admin') && to.path !== '/admin/login' && localStorage.getItem('token') == null)
        next('/admin/login');
    next();
})

export default router;
