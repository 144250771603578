import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import axios from './plugins/axios';
import env from './plugins/env';
import router from './plugins/router';
import store from './plugins/store';
import utils from './plugins/utils';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$env = env;
Vue.prototype.$http = axios;
Vue.prototype.$utils = utils;

const app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
});

app.$mount('#app');
