import axios from 'axios';
import {NODE_ENV, APP_MODE} from './env.js';

const DEV = NODE_ENV === 'development';
const PPO = APP_MODE === 'ppo';
axios.defaults.baseURL = DEV ? 'http://localhost:3000' : 'https://' + (PPO ? 'rankingi' : 'gls') + '.rubiart.pl';
axios.defaults.withCredentials = true;

axios.interceptors.request.use((request) => {
    request.headers.Authorization = 'Bearer: ' + localStorage.getItem('token');
    return request;
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status === 403 && !window.location.href.endsWith('/admin/login')) {
        localStorage.removeItem('token');
        window.location.href = window.location.origin + '/admin/login';
    }
    return Promise.reject(error);
});

export default axios;
